import React from 'react'
import Img from 'gatsby-image'

import AniLink from 'gatsby-plugin-transition-link/AniLink'

export default function PostLink({ post }) {
  let bgTransition = '#edf2f7'

  if (typeof window !== 'undefined') {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      bgTransition = '#313233'
    } else {
      bgTransition = '#edf2f7'
    }
  }

  return (
    <article className="portfolio--card">
      <div className="card__image">
        <Img fluid={post.frontmatter.squareImage.childImageSharp.fluid} />
      </div>

      <div class="portfolio--list">
        <h3>{post.frontmatter.title}</h3>
        <p>{post.frontmatter.excerpt}</p>
        <AniLink
          cover
          direction="left"
          duration={0.7}
          bg={bgTransition}
          to={post.frontmatter.slug}
        >
          Read more...
        </AniLink>
      </div>
    </article>
  )
}
