import React from 'react'

const Title = (props) => {
    return (
        <div class="container" style={{ textAlign: 'center' }}>
            <h1>{props.title}</h1>
        </div>
    )
}

export default Title