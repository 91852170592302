import React from "react"
import { graphql } from "gatsby"
import PostLink from "../components/postLink"
import Layout from "../components/Layout"
import "../assets/stylesheets/pages/portfolioPage.scss"
import Title from "../components/Title"

const portfolioPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {

  
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return (
    <Layout>
      <Title title="Portfolio" />
      <div class="portfolio--list">
        {Posts}
      </div>
    </Layout>
  )
}
export default portfolioPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            excerpt
            squareImage {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                  }
                }
            }
          }
        }
      }
    }
  }
`